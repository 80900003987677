import env_config from "env_config";

export const imgSelector = ({ data, imgType }) => {
  const baseUrl = `${env_config.apiImageHostName}`;

  const {
    fileLogoSquare,
    fileLogoRectangle,
    fileLogo,
    personPicture,
    picture,
  } = data || {};

  const logo =
    fileLogoSquare || fileLogoRectangle || fileLogo || personPicture || picture;
  const checkLogo = logo?.split(".")?.[1] ? logo : [logo, "jpg"]?.join(".");
  const defaultImage =
    data?.personPicture || imgType === "person"
      ? "/images/no-avatar.svg"
      : "/images/no-company.svg";

  const imageUrl = `${baseUrl}${checkLogo}`;

  if (!logo) {
    return defaultImage;
  }

  return imageUrl;
};
