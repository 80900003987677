import { MainWrapper } from '@/components/ui/box/MainWrapper';
import { RoslindaleText } from '@/components/ui/text/RoslindaleText';
import {
  h_400_10_135_04,
  h_400_14_100_64,
  // h_700_10_100,
  h_700_16_100_32,
  // h_700_7_135,
} from '@/styles/fontStyles';
import { Box, Image, Flex, Text } from '@chakra-ui/react';
import React from 'react';
// import WaterfallSVG from "@/assets/svg/waterfall.svg";
// import LineUpSVG from "@/assets/svg/line-up.svg";
// import { useBreakPoints } from "@/hooks/useBreakPoints";
import SchemaSVG from '@/assets/svg/Schema.svg';
import { useSelector } from 'react-redux';
import { getUserIsVerify } from '@/store/auth/auth.selectors';

function VentureCapital() {
  // const { isMobile } = useBreakPoints();
  const isLogIn = useSelector(getUserIsVerify());
  return (
    <MainWrapper customStyleOuter={{ px: { base: 0, xl: '0px' } }}>
      <Flex
        // pl={{ base: "none", xl: "61px" }}
        justifyContent={'space-between'}
        alignItems={{ base: 'center' }}
        flexDirection={{ base: 'column', lg: 'row' }}
        // pb={{ base: "42px" }}
        px={{ base: '24px', lg: '40px' }}
        pt={{ base: '8px' }}
        // pl={{ xl: "61px" }}
      >
        <Flex
          flexDirection={'column'}
          alignItems={'center'}
          py={{ lg: '115px' }}
          maxW={'710px'}
          mr={'10px'}
        >
          {!isLogIn ? (
            <RoslindaleText
              customStyle={{
                mt: { base: '40px', md: 'unset' },
                mb: { base: '24px', lg: '48px' },
                fontSize: { base: '24px', md: '42px' },
                maxW: '638px',
                textAlign: { base: 'center', lg: 'center' },
              }}
              headingType={'h2'}
            >
              High Growth Companies Are Often VC Investments
            </RoslindaleText>
          ) : (
            <Box>
              <RoslindaleText
                customStyle={{
                  mt: { base: '40px', md: 'unset' },
                  mb: { base: '24px', lg: '48px' },
                  fontSize: { base: '24px', md: '42px' },
                  maxW: '638px',
                  textAlign: { base: 'center', lg: 'center' },
                }}
                headingType={'h2'}
              >
                And many of the world’s <u>fastest growing companies</u> come
                from <u>VC investments</u>
              </RoslindaleText>
            </Box>
          )}
          <Text
            textAlign={{ base: 'center', lg: 'center' }}
            color={'mainBlue'}
            {...h_400_14_100_64}
            maxW={'638px'}
          >
            Since 2006, the number of deals and the capital raised have been
            growing exponentially, reaching a total of $164 billion invested
            across 11,651 deals in 2020 alone.
          </Text>
        </Flex>
        <Box
          mt={{ base: '50px', md: '0px' }}
          ml={{ xl: '70px' }}
          // minH={{ lg: "472px" }}
        >
          <Text {...h_700_16_100_32} mb={'24px'} textAlign={'center'}>
            Venture capital investments over time
          </Text>
          {/* <Flex mt={"24px"} mb={"9px"} alignItems={"center"}>
              <WaterfallSVG />
              <Text {...(isMobile ? h_700_7_135 : h_700_16_100_32)} ml={"10px"}>
                Capital Invested ($B)
              </Text>
              <Flex ml="24px" alignItems={"center"}>
                <LineUpSVG />
                <Text
                  {...(isMobile ? h_700_7_135 : h_700_16_100_32)}
                  ml={"10px"}
                >
                  Deal/Company Count
                </Text>
              </Flex>
            </Flex>{" "} */}
          {/* <Image
              minW={{ xl: "580px" }}
              w={"100%"}
              src="/images/111.png"
              alt="shema"
            /> */}
          <Box minW={{ xl: '580px' }}>
            <Box display={{ base: 'none', md: 'block' }}>
              <SchemaSVG width='100%' height='100%' />
            </Box>
            <Box display={{ base: 'block', md: 'none' }}>
              <Image src='/images/venture.png' alt='schema' loading='lazy' />
            </Box>
          </Box>
          <Text
            maxW={'535px'}
            mt={'29px '}
            color={'venture'}
            {...h_400_10_135_04}
          >
            Source: NVCA, 2021. “NVCA 2021 Yearbook”, with underlying data
            provided by Pitchbook. Analysis period 2002-2020. The chart as
            displayed includes the total capital raised for each year in the VC
            asset class and the number of investments made by VC funds.
          </Text>
        </Box>
      </Flex>
    </MainWrapper>
  );
}

export default VentureCapital;
