import NewsItem from './NewsItem';

const NewsList = ({ data }) => {
  return (
    <>
      {data && (
        <div className='w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_50px,_black_calc(100%-50px),transparent_100%)]'>
          <NewsItem {...{ data }} />
        </div>
      )}
    </>
  );
};

export default NewsList;
