export const cardCompanyResearch = [
  {
    image: "/images/copi.ai.png",
    nameCompany: "Copy.ai",
    address: "Memphis, TN, USA",
    founded: "2020",
    stage: "Series A",
    totalRaised: "$13.9M",
    raised: "$1.60M",
    investors: "11",
  },
  {
    image: "/images/flat.png",
    nameCompany: "Flaticon",
    address: "Los Angeles, CA, USA",
    founded: "2019",
    stage: "Seed",
    totalRaised: "$450M",
    raised: "$500K",
    investors: "5",
  },
  {
    image: "/images/flut.png",
    nameCompany: "Flutterflow",
    address: "Mountain View, CA, USA",
    founded: "2013",
    stage: "Series A",
    totalRaised: "$1.60M",
    raised: "$13.9M",
    investors: "5",
  },
  {
    image: "/images/rad.png",
    nameCompany: "Rad AI",
    address: "New York, NY, USA",
    founded: "2000",
    stage: "Series A",
    totalRaised: "$100.2K",
    raised: "$300M",
    investors: "5",
  },
  {
    image: "/images/flutFile.png",
    nameCompany: "Flatfile",
    address: "San Francisco, CA, USA",
    founded: "2020",
    stage: "Series B",
    totalRaised: "$500K",
    raised: "$100.2K",
    investors: "2",
  },
  {
    image: "/images/substack.png",
    nameCompany: "Substack",
    address: "Las Vegas, NV, USA",
    founded: "2013",
    stage: "Active",
    totalRaised: "$300M",
    raised: "$450M",
    investors: "3",
  },
];
export const customTextSection = ["Founded", "Stage", "Total Raised"];
