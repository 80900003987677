export const formatNumber = (num) => {
  num = parseFloat(num);

  if (!isNaN(parseFloat(num))) {
    if (num >= 1000000000) {
      return `$${(num / 1000000000).toFixed(1).replace(/\.0$/, "")}B`;
    } else if (num >= 1000000) {
      return `$${(num / 1000000).toFixed(1).replace(/\.0$/, "")}M`;
    } else if (num >= 1000) {
      return `$${(num / 1000).toFixed(1).replace(/\.0$/, "")}k`;
    } else {
      return `$${num}`;
    }
  } else {
    return "—";
  }
};

export const formatNumberFull = (num) => {
  num = parseFloat(num);

  if (!isNaN(parseFloat(num))) {
    if (num >= 1000000000) {
      return `$${(num / 1000000000).toFixed(1).replace(/\.0$/, "")} Billion(s)`;
    } else if (num >= 1000000) {
      return `$${(num / 1000000).toFixed(1).replace(/\.0$/, "")} Million(s)`;
    } else if (num >= 1000) {
      return `$${(num / 1000).toFixed(1).replace(/\.0$/, "")} thousand(s)`;
    } else {
      return `$${num}`;
    }
  } else {
    return "—";
  }
};

export const formatMoney = (num) => {
  num = parseFloat(num) || null;
  return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;
};
