import { MainWrapper } from "@/components/ui/box/MainWrapper";
import { Grid, GridItem, useMediaQuery } from "@chakra-ui/react";
import { FundraisingRounds } from "./components/FundraisingRounds";
import { PopularResearchTags } from "./components/PopularResearchTags";
import { RecentResearchReports } from "./components/RecentResearchReports";

export const FundraisingBlock = ({ fundraising, tags }) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  return (
    <MainWrapper
      customStyleOuter={{
        mt: { base: "40px", md: "50px", xl: "40px" },
      }}
      customStyleInner={{ w: "100%", maxW: "1360px" }}
    >
      <Grid
        templateAreas={{
          base: `"rounds" "reports"`,
          md: `"rounds" "tags" "reports"`,
          forCard3: `"rounds tags" "rounds reports"`,
        }}
        gridTemplateRows={{ base: "repeat(auto, auto)", xl: "auto 322px" }}
        gridTemplateColumns={{
          base: "1fr",
          md: "1fr",
          forCard3: "calc(50% - 10px) calc(50% - 10px)",
        }}
        gap={"20px"}
      >
        <GridItem area={"rounds"}>
          <FundraisingRounds {...{ fundRaisingList: fundraising }} />
        </GridItem>
        {!isMobile && (
          <GridItem area={"tags"}>
            <PopularResearchTags {...{ tagsList: tags }} />
          </GridItem>
        )}
        <GridItem area={"reports"}>
          <RecentResearchReports {...{ fundraisingList: fundraising }} />
        </GridItem>
      </Grid>
    </MainWrapper>
  );
};
