import { Text } from "@chakra-ui/react";

const ErrorText = ({ title, error_style }) => {
  return (
    <Text
      pos={"absolute"}
      left={"1px"}
      bottom={"-15px"}
      fontSize={"10px"}
      fontWeight={700}
      lineHeight={"1"}
      color={"errorRed"}
      {...error_style}
    >
      {title}
    </Text>
  );
};

export default ErrorText;
