import { CustomElement } from "@/components/ui/box/CustomElement";
import DefaultImage from "@/components/ui/DefaultImage";
import { useActions } from "@/hooks/useActions";
import {
  h_400_12_135_48,
  h_700_14_120_28,
  h_700_16_100_32,
} from "@/styles/fontStyles";
import { recentResearchReportsBlock } from "@/utils/masksForResearchPage";
import { Flex, Text } from "@chakra-ui/react";
import Link from "next/link";

export const RecentResearchReportElement = ({ data }) => {
  const { setCurrentCompany } = useActions();

  return (
    <CustomElement
      {...{
        variant: "whiteBtnOutline",
        customStyle: {
          borderRadius: "8px",
          p: "12px",
          h: "full",
          minH: "226px",
          flex: "1",
          border: "1px solid",
          borderColor: "menuGray",
          minW: "136px",
          _hover: {
            boxShadow: "none",
          },
          cursor: "default",
        },
      }}
    >
      <Flex flexDir={"column"} w={"full"} gap={"12px"} align={"center"}>
        <Link
          href={`/companies/${data?.resEntityCompany?.slug}`}
          alt={data?.resEntityCompany?.nameBrand}
        >
          <DefaultImage
            src={data?.logo}
            options={{
              alt: data?.name,
              style: {
                width: "80px",
                height: "80px",
                minW: "80px",
                maxW: "80px",
                justify: "center",
                align: "center",
                alignSelf: "center",
                justifySelf: "center",
                p: "6px",
                borderRadius: "6px",
                boxShadow: "0px 0px 14px 0px rgba(114, 114, 114, 0.25)",
                objectFit: "scale-down",
              },
            }}
          />
        </Link>

        <Flex
          justify={"space-evenly"}
          flexDir={"column"}
          gap={"12px"}
          w={"100%"}
          align={"center"}
          maxW={"100%"}
        >
          <Text
            as={"h4"}
            maxW={"100%"}
            {...h_700_16_100_32}
            textAlign={"center"}
            isTruncated
            pt={"2px"}
            pb={"1px"}
          >
            {data?.name}
          </Text>
          <Flex
            gap={"8px"}
            justifyContent={"center"}
            w={"100%"}
            maxW={{
              base: "calc(100vw/2 - 56px)",
            }}
          >
            {recentResearchReportsBlock.map((el) => {
              return (
                <Flex
                  flexDir={"column"}
                  key={"recentResearchElement" + el?.id}
                  gap={"4px"}
                  maxW={"50%"}
                  minW={el?.title === "Total Raised" ? "73px" : "0px"}
                >
                  <Text
                    {...h_400_12_135_48}
                    color={"gray"}
                    textAlign={"center"}
                    whiteSpace={"nowrap"}
                    isTruncated
                  >
                    {el.title}
                  </Text>
                  <Text
                    {...h_700_14_120_28}
                    color={"mainBlue"}
                    textAlign={"center"}
                    whiteSpace={"nowrap"}
                    isTruncated
                  >
                    {data?.[el?.field]}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Link
          href={`/companies/${data?.resEntityCompany?.slug}`}
          alt={data?.resEntityCompany?.nameBrand}
          onClick={() => setCurrentCompany(data?.resEntityCompany)}
          style={{ maxWidth: "100%" }}
        >
          <CustomElement
            title={"View Details"}
            variant={"blueBtn"}
            customStyle={{
              ...h_700_14_120_28,
              p: { base: "14px 18px 11px 18px", lg3: "14px 18px 11px 18px" },

              w: { base: "122px", md: "141px" },
              maxW: "100%",
              alignSelf: "center",
              whiteSpace: "nowrap",
            }}
          />
        </Link>
      </Flex>
    </CustomElement>
  );
};
