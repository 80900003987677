import { h_700_16_100_32 } from "@/styles/fontStyles";
import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { RunningImageHandler } from "./RunningImageHandler";
import env_config from "env_config";

export const RunningImageString = ({ currentCompaniesList }) => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const checkImageExists = async (url) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.onload = () => resolve(true);
          img.onerror = () => resolve(false);
          img.src = url;
        });
      };

      currentCompaniesList?.forEach((item) => {
        if (companies.length >= 40) return;
        if (!item.fileLogoSquare) return;

        const imageUrl = `${env_config.apiImageHostName}${item?.fileLogoSquare}`;
        checkImageExists(imageUrl).then((isImageExists) => {
          if (isImageExists) {
            setCompanies((prev) => [...prev, item]);
          }
        });
      });
    }, 10);

    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompaniesList]);

  return (
    <Flex
      flexDir={"column"}
      align={"center"}
      gap={"24px"}
      mb={{ base: "42px", md: "14px", lg: "120px" }}
    >
      <Text
        {...h_700_16_100_32}
        color={"mainBlue"}
        px={"20px"}
        textAlign={"center"}
      >
        Research early, fast growing startups like these with aVenture
      </Text>
      <RunningImageHandler data={companies?.slice(0, 20)} />
      <RunningImageHandler runDir={"left"} data={companies?.slice(21, 40)} />
    </Flex>
  );
};
