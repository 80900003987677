import { Box } from '@chakra-ui/react';
import { BlueTextBoxArrow } from './BlueTextBoxArrow';

export const BlockWithBlueArrowTitle = ({
  title,
  heading,
  children,
  customStyleOuterBox,
  customStyleInnerBox,
}) => {
  return (
    <Box
      className="hide-scrollbar"
      pt={'20px'}
      bgColor={'dashboardBackground'}
      borderRadius={'10px'}
      boxShadow={{
        base: '',
        md: '0px 0px 14px 0px rgba(114, 114, 114, 0.25)',
        // md: '0px 0.10563px 2.73498px 0px rgba(141, 141, 141, 0.09), 0px 0.32749px 10.32032px 0px rgba(141, 141, 141, 0.12), 0px 0.85182px 25.284px 0px rgba(141, 141, 141, 0.13), 0px 2.11393px 53.43982px 0px rgba(141, 141, 141, 0.15), 0px 5px 117px 0px rgba(141, 141, 141, 0.23)',
      }}
      {...customStyleOuterBox}
    >
      <BlueTextBoxArrow {...{ title, heading }} />
      <Box p={'20px 20px 20px 20px'} {...customStyleInnerBox}>
        {children}
      </Box>
    </Box>
  );
};
