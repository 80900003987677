import * as Yup from "yup";

export const signUpSchema = Yup.object({
  userFirstName: Yup.string()
    .trim()
    .required("*Enter First Name")
    .matches(/^[a-zA-Z]+$/, "*The name should contain only letters"),

  userLastName: Yup.string()
    .trim()
    .required("*Enter Last Name")
    .matches(/^[a-zA-Z]+$/, "*The surname should contain only letters"),
  email: Yup.string().trim().email("*Incorrect email").required("*Enter email"),
});

export const signInSchema = Yup.object({
  email: Yup.string().trim().email("*Incorrect email").required("*Enter email"),
  password: Yup.string()
    .required("*Incorrect password")
    .min(8, "*min 8 char")
    .max(35, "*max 35 char"),
});

export const passwordSchema = Yup.object({
  password: Yup.string()
    .required("*Enter password")
    .min(8, "*min 8 char")
    .max(35, "*max 35 char")
    .matches(/^(?=.*[A-Z]).*$/, "*should contain at least 1 capital letter")
    .matches(/^(?=.*[0-9]).*$/, "*should contain at least 1 number")
    .matches(
      /^(?=.*[!@#$%^&*()+\-=]).*$/,
      "*should contain symbol  ! @ # $ % ^ & * ( ) +  "
    ),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "*Passwords don`t match each other"
  ),
});

export const emailSchema = Yup.object({
  email: Yup.string().trim().email("*Incorrect email").required("*Enter email"),
});

export const firstLastPasswordSchema = Yup.object({
  userFirstName: Yup.string().trim().required("*Enter First Name"),
  userLastName: Yup.string().trim().required("*Enter Last Name"),
  password: Yup.string().min(8, "*min 8 char").max(35, "*max 35 char"),
});

export const changePasswordSchema = Yup.object({
  password: Yup.string()
    .required("*Enter password")
    .min(8, "*min 8 char")
    .max(35, "*max 35 char"),
  newPassword: Yup.string()
    .required("*Enter  new password")
    .min(8, "*min 8 char")
    .max(35, "*max 35 char")
    .matches(/^(?=.*[A-Z]).*$/, "*Should contain at least 1 capital letter")
    .matches(/^(?=.*[0-9]).*$/, "*Should contain at least 1 number")
    .matches(
      /^(?=.*[!@#$%^&*()+\-=]).*$/,
      "*Should contain symbol  ! @ # $ % ^ & * ( ) +  "
    ),
  confirmNewPassword: Yup.string().oneOf(
    [Yup.ref("newPassword"), null],
    "*Passwords don`t match each other"
  ),
});

const url =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const socialSchema = Yup.object({
  linkedin: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .matches(url, "Enter correct linkedin link!"),

  twitter: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .matches(url, "Enter correct X link!"),

  website: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .matches(url, "Enter correct url!"),
});

const phone =
  /^(1[ \-\+]{0,3}|\+1[ -\+]{0,3}|\+1|\+)?((\(\+?1-[2-9][0-9]{1,2}\))|(\(\+?[2-8][0-9][0-9]\))|(\(\+?[1-9][0-9]\))|(\(\+?[17]\))|(\([2-9][2-9]\))|([ \-\.]{0,3}[0-9]{2,4}))?([ \-\.][0-9])?([ \-\.]{0,3}[0-9]{2,4}){2,3}$/;

export const infoProfileSchema = Yup.object({
  phone: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .matches(phone, "Enter correct phone number!"),
});

export const contactUsSchema = Yup.object({
  firstName: Yup.string().trim().required("*Enter First Name"),
  lastName: Yup.string().trim().required("*Enter Last Name"),
  email: Yup.string().trim().email("*Incorrect email").required("*Enter email"),
  companyName: Yup.string().trim().required("*Enter Company Name"),
  phoneNumber: Yup.string()
    .matches(phone, "Enter correct phone number!")
    .required("Please enter "),
  question: Yup.string().trim().required("*Enter Question"),
  request: Yup.string().trim().required("*Enter Details"),
});
