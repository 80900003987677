import BlueInfoBox from '@/components/ui/blueInfoBox/BlueInfoBox';
import { MainWrapper } from '@/components/ui/box/MainWrapper';
import { h_400_10_135_04, h_700_16_100_32 } from '@/styles/fontStyles';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import GrafikSVG from '@/assets/svg/grafik.svg';
import { useSelector } from 'react-redux';
import { getUserIsVerify } from '@/store/auth/auth.selectors';

function RiskAdjusted() {
  const isLogIn = useSelector(getUserIsVerify());
  const underlineStyle = {
    textDecoration: 'underline',
    marginLeft: '6px',
  };
  return (
    <MainWrapper
      {...{
        customStyleOuter: { bgColor: 'mainBlue', mb: '40px' },
        customStyleInner: {
          maxW: '1360px',
          alignItems: 'center',
          flexDir: { base: 'column-reverse', lg: 'row' },
        },
      }}
    >
      <Flex
        flex={1}
        mt={'41px'}
        justifyContent={'center'}
        h={{ base: '', md: 'fit-content' }}
        flexDirection={'column'}
        maxW={'528px'}
        mb={{ base: '40px', md: '40px', lg: '40px' }}
        px={{ base: '24px', md: '0px' }}
      >
        <Heading
          as={'h4'}
          color={'white'}
          {...h_700_16_100_32}
          textAlign={'center'}
          mb={'12px'}
        >
          Risk and return across different asset classes
        </Heading>
        <Box maxW={'528px'}>
          <GrafikSVG width='100%' height='100%' />
        </Box>
        {/* <Image src="/images/graphik.png" alt="schema" /> */}
        <Text color={'white'} {...h_400_10_135_04} mt={'17px'}>
          Source: Morgan Stanley (2020), “Public to Private Equity in the United
          States: A Long-Term Look”. Past performance does not ensure future
          results and expected risk, returns, or other projections may not
          reflect future performance. All asset classes correspond to the period
          1984-2015, except for venture capital funds, which reflect the period
          1984-2013, representing the most recent data available at the time of
          the study publication.
        </Text>
      </Flex>
      <BlueInfoBox
        customStyle={{
          mt: { base: '40px', lg: 'unset' },
          mb: { base: '24px', lg: '48px' },
          fontSize: { base: '24px', md: '42px' },
          lineHeight: { base: 'normal', md: 'normal' },
          color: 'white',
          textAlign: { base: 'center', lg: 'center' },
        }}
        titleText={
          isLogIn ? (
            <>
              Venture investments can provide
              <span style={underlineStyle}> better risk-adjusted returns</span>
              ...
            </>
          ) : (
            'Venture Has Had Better Risk-Adjusted Returns'
          )
        }
        text='Since 1984, venture funds have exhibited comparable risk to some public and private equity investments, while potentially offering better returns.'
        px={{ base: '37px', md: '74px', lg: '43px' }}
        styleOfBox={{
          flex: 1,
          py: { base: '0px', lg: 0 },
          justifyContent: 'center',
          alignItems: 'center',
          px: { base: '52px', lg: 0 },
          textAlign: 'start',
          minH: { base: 'fit-content', md: 'fit-content', lg: '500px' },
          marginLeft: { base: '0px', lg: '10px' },
        }}
        styleOfAddition={{
          textAlign: { base: 'center', lg: 'center' },
          mb: { base: '0px' },
        }}
        headingType={'h2'}
      />
    </MainWrapper>
  );
}

export default RiskAdjusted;
