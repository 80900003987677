import ExtendedAuthInput from '@/components/ui/auth/ExtendedAuthInput';
import { CustomElement } from '@/components/ui/box/CustomElement';
import { MainWrapper } from '@/components/ui/box/MainWrapper';
import { ButtonDefault } from '@/components/ui/buttons/DefaultBtn';
import { SearchInput } from '@/components/ui/inputs/SearchInput';
import { RoslindaleText } from '@/components/ui/text/RoslindaleText';
import {
  useFreeAccountByEmailMutation,
  useSignUpWithEmailMutation,
} from '@/services/auth.service';
import { getUserIsVerify } from '@/store/auth/auth.selectors';
import { emailSchema } from '@/validations/form_validations';
import { Box, Flex } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import Link from 'next/link';
import React, { useState } from 'react';
import { get, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

const blueBlockStyleImage = {
  backgroundImage: 'url("/images/aVenture.png")',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'start',
};

const textBlockStyle = {
  flex: 1,
  padding: '20px',
  color: 'white',
};

function CreateAccBlock() {
  // const [signUpWithEmail] = useSignUpWithEmailMutation();
  const [freeAccountByEmail] = useFreeAccountByEmailMutation();
  const isLogIn = useSelector(getUserIsVerify());

  const {
    control,
    watch,
    handleSubmit,
    // setValue,
    // getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(emailSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmitEmailHandler = (data) => {
    // signUpWithEmail({ email: data?.email });
    if (data?.email?.trim() !== '') {
      freeAccountByEmail({ email: data?.email });
    }
  };

  return (
    <MainWrapper>
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        maxW={'1057px'}
        bgColor={'mainBlue'}
        mx={{ md: '40px', xl: '157px' }}
        height={'274px'}
        style={blueBlockStyleImage}
        borderRadius={{ md: '10px' }}
      >
        <Box style={{ flex: 1 }} display={{ base: 'none', md: 'flex' }}></Box>

        <Flex
          flexDir={{ base: 'column' }}
          align={{ base: 'center', lg: 'flex-start' }}
          style={textBlockStyle}
        >
          <RoslindaleText
            customStyle={{
              maxW: '424px',
              textAlign: { base: 'center', lg: 'start' },
              color: 'white',

              mb: '30px',
              fontSize: { base: '22px', md: '34px', xl: '44px' },
            }}
            headingType={'h3'}
          >
            Create a Free Research Account
          </RoslindaleText>
          <Flex
            alignItems={{ base: 'center', md: 'flex-start' }}
            flexDirection={{ base: 'column', md: 'row' }}
            gap={'8px'}
            as={'form'}
            onSubmit={handleSubmit(onSubmitEmailHandler)}
          >
            <Link
              href={isLogIn ? '/companies' : '/sign-up'}
              alt={isLogIn ? 'Companies' : 'Sign Up'}
            >
              <ButtonDefault
                hoverBgColor={'darkRedNew'}
                customStyles={{
                  px: '24px',
                  py: '8px',
                  borderRadius: '4px',
                  height: '45px',

                  minW: { base: '100%', md: '131px' },
                }}
                backgroundColor={'red'}
                // title="Access Now"
                title="Start Research"
              />
            </Link>
            <Link
              href={'https://calendly.com/aventurevc/aventure-call'}
              alt={'Book Demo'}
              style={{ width: '100%' }}
              rel="noreferrer"
              target="_blank"
            >
              <CustomElement
                {...{
                  variant: 'outlineBtn',
                  title: 'Book Demo',
                  customStyle: {
                    h: '43px',
                    border: '3px solid',
                    borderColor: '#29AE61',
                    color: 'white',
                    minW: { base: '100%', md: '131px' },
                    _hover: {
                      color: 'white',
                      borderColor: '#29AE61',
                    },
                  },
                }}
              ></CustomElement>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </MainWrapper>
  );
}

export default CreateAccBlock;
