import { Flex, Box, Text } from '@chakra-ui/react';
import { BlueTextBoxArrow } from '@/components/ui/box/BlueTextBoxArrow';
import NewsList from './NewsList';
import { MainWrapper } from '@/components/ui/box/MainWrapper';

const NewsHomeLine = ({ news, title }) => {
  return (
    <Flex flexDir={'column'} rowGap={'20px'}>
      <MainWrapper>
        <BlueTextBoxArrow
          {...{
            title,
            boxStyles: { w: 'fit-content' },
          }}
        />
      </MainWrapper>
      <Flex
        flexDir={'column'}
        align={'center'}
        gap={'24px'}
        mb={{ base: '42px', md: '14px', lg: '0px' }}
      >
        <NewsList {...{ data: news }} />
      </Flex>
    </Flex>
  );
};

export default NewsHomeLine;
