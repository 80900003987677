export const buttonsSecondLineAfterSearch = [
  // {
  //   title: 'Explore Funds',
  //   variantBtn: 'outlineBtn',
  //   href: '/investors',
  // },
  { title: "Explore Companies", variantBtn: "redBtn", href: "/companies" },
];

export const maskFundraisingRoundsBlock = [
  { title: "Logo", field: "fileLogo" },
  { title: "Name", field: "nameBrand" },
  {
    title: "address",
    field: ["geoLocationCity", "geoLocationStateAbbrev", "geoLocationCountry"],
  },
  { title: "Raised", field: "amountRaised" },
  { title: "Round", field: "round" },
  { title: "Investors", field: "numberOfInvestors" },
];

export const recentResearchReportsBlock = [
  { id: 0, title: "Stage", field: "stage" },
  { id: 1, title: "Total Raised", field: "total_raised" },
];

export const customTextFundResearch = [
  { title: "Vintage", field: "yearEstablished" },
  { title: "Fund Type", field: "typeFund" },
  { title: "Committed Capital", field: "committedCapitalTotal" },
];
