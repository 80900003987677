export const addressSelector = ({
  mainAddress = null,
  data = null,
  type = null,
}) => {
  let creator;
  switch (type) {
    case "color":
      creator = createAddressColor;
      break;
    case "simple":
      creator = createAddressSimple;
      break;
    default:
      creator = createAddress;
  }

  if (mainAddress)
    return type === "color"
      ? createAddressColor(mainAddress)
      : createAddress(mainAddress);

  if (data?.length) {
    let temp = null;
    temp = data?.find((el) => el?.isHq);
    if (temp) {
      return creator(temp);
    } else {
      temp = data?.find((el) => el?.isPrimary);
      if (temp) {
        return creator(temp);
      } else {
        temp = data?.find((el) => el?.isCurrent);
        if (temp) {
          return creator(temp);
        } else {
          temp = data[0];
          return creator(temp);
        }
      }
    }
  }
  return "—";
};

function createAddressColor(data) {
  let temp = {};
  if (data?.address?.city?.name) {
    temp = {
      ...temp,
      city: { name: data?.address.city.name, color: data?.address.city.color },
    };
  }
  if (data?.address?.state?.stateAbbrev) {
    temp = {
      ...temp,
      state: {
        name: data?.address.state.stateAbbrev,
        color: data?.address.state.color,
      },
    };
  }
  if (data?.address?.country?.countryCodeChar2) {
    temp = {
      ...temp,
      country: {
        name: data?.address.country.countryCodeChar2,
        color: data?.address.country.color,
      },
    };
  } else if (data?.address?.country?.countryCodeChar3) {
    temp = {
      ...temp,
      country: {
        name: data?.address.country.countryCodeChar3,
        color: data?.address.country.color,
      },
    };
  }
  return Object.keys(temp)?.length ? temp : "—";
}

function createAddress(data) {
  const components = [
    data?.address?.city?.name,
    data?.address?.state?.stateAbbrev,
    data?.address?.country?.countryCodeChar2 ||
      data?.address?.country?.countryCodeChar3,
  ].filter(Boolean);
  return components.length ? components.join(", ") : "—";
}

function createAddressSimple(data) {
  const components = [
    data?.address?.city,
    data?.address?.state,
    data?.address?.country,
  ].filter(Boolean);
  return components.length ? components.join(", ") : "—";
}
