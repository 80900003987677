import { CustomElement } from '@/components/ui/box/CustomElement';
import { Flex } from '@chakra-ui/react';
import { BlockWithBlueArrowTitle } from '@/components/ui/box/BlockWithBlueArrowTitle';
import { FundraisingRoundsElement } from './FundraisingRoundsElement';
import Link from 'next/link';

export const FundraisingRounds = ({ fundRaisingList }) => {
  return (
    <BlockWithBlueArrowTitle
      title={'Fundraising Rounds'}
      customStyleOuterBox={{ flex: '1', h: '100%', maxW: { base: '100vw' } }}
      customStyleInnerBox={{ pt: '20px' }}
    >
      <Flex flexDir={'column'} gap={'12px'}>
        {fundRaisingList?.slice(0, 7).map((el) => {
          return <FundraisingRoundsElement key={el?.id} {...{ data: el }} />;
        })}
      </Flex>
      <Link href={'/companies'}>
        <CustomElement
          variant={'seeMoreBtn'}
          title={'See More'}
          customStyle={{ mx: 'auto', mt: '12px' }}
        />
      </Link>
    </BlockWithBlueArrowTitle>
  );
};
