import { BlockWithBlueArrowTitle } from "@/components/ui/box/BlockWithBlueArrowTitle";
import { CustomElement } from "@/components/ui/box/CustomElement";
import { Flex, useMediaQuery } from "@chakra-ui/react";
import { RecentResearchReportElement } from "./ResentResearchReportElement";
import { formatNumber } from "@/utils/formatNumber";
import { useRouter } from "next/navigation";
import { imgSelector } from "@/utils/imgSelector";

export const RecentResearchReports = ({ fundraisingList }) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [isDeck] = useMediaQuery("(max-width: 1140px)");
  const router = useRouter();

  return (
    <BlockWithBlueArrowTitle
      title={"Recent Research Reports"}
      customStyleOuterBox={{
        h: "100%",
        maxW: { base: "calc(100vw - 12px)", md: "unset" },
        bgColor: isMobile ? "transparent" : "dashboardBackground",
      }}
      customStyleInnerBox={{
        p: isMobile ? "20px 20px 0px 20px" : "20px 20px 20px",
      }}
    >
      <Flex
        w={"full"}
        maxW={"100%"}
        justify={"space-evenly"}
        gap={{ base: "12px", md: "20px" }}
      >
        {fundraisingList &&
          [
            ...(isMobile || isDeck
              ? fundraisingList.slice(0, 2)
              : fundraisingList.slice(0, 3)),
          ].map((el) => {
            const dataRes = el?.resEntity || el?.resEntityCompany;
            return (
              <RecentResearchReportElement
                key={"recentResearch" + el?.id}
                {...{
                  data: {
                    logo: imgSelector({ data: dataRes }),
                    name: dataRes?.nameBrand,
                    stage: el?.round,
                    total_raised: formatNumber(
                      el?.amountRaised || el?.valuationPostMoney
                    ),
                    resEntityCompany: dataRes,
                  },
                  handler: () => {},
                }}
              />
            );
          })}
      </Flex>
      {isMobile && (
        <CustomElement
          variant={"seeMoreBtn"}
          title={"See More"}
          handler={() => {
            router.push("/companies");
          }}
          customStyle={{ mx: "auto", mt: "12px" }}
        />
      )}
    </BlockWithBlueArrowTitle>
  );
};
