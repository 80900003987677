import Logo from "@/assets/svg/aventure_logo_pop.svg";
import FormButton from "@/components/ui/auth/FormButton";
import { MainWrapper } from "@/components/ui/box/MainWrapper";
import { RoslindaleText } from "@/components/ui/text/RoslindaleText";
import { useFreeAccountByEmailMutation } from "@/services/auth.service";
import { getUserIsVerify } from "@/store/auth/auth.selectors";
import {
  h_400_16_135_64,
  h_700_14_100,
  h_700_24_normal_48,
} from "@/styles/fontStyles";
import { emailSchema } from "@/validations/form_validations";
import { Box, Button, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

export const FundResearch = ({ funds }) => {
  const data = funds?.slice(0, 6);
  const isLogIn = useSelector(getUserIsVerify());
  const isDev = process.env.NEXT_ENVIRONMENT === "development";
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  return (
    <MainWrapper
      customStyleOuter={{
        px: { base: "0px", lg: "40px" },
        mt: { base: "60px" },
        // mb: { base: '55px', md: '76px', xl: '40px' },
        mb: { base: "40px" },
      }}
      customStyleInner={{
        // flexDir: { base: 'column-reverse', xl: 'row' },
        justify: "center",
        gap: { base: "40px", xl: "20px" },
        alignItems: { base: "center", md: "center" },
      }}
    >
      {/* <BlockWithBlueArrowTitle
        title={'Fund Research'}
        customStyleOuterBox={{
          pt: '0px',
          bgColor: 'transparent',
          boxShadow: 'none',
          flex: '50%',
          maxW: { base: '100%', xl: '50%' },
          minW: { base: '100%', md: '636px' },
        }}
        customStyleInnerBox={{
          p: { base: '32px 0 0 24px', md: '32px 0 0 0' },
          overflow: { base: 'hidden', md: 'unset' },
        }}
      >
        <Flex
          flexDir={'column'}
          align={'center'}
          w={'fit-content'}
          maxW={'full'}
          flex={'1'}
        >
          <Flex
            position={'relative'}
            maxW={{ base: '100%', md: 'fit-content' }}
            overflow={{ base: 'hidden', md: 'unset' }}
          >
            <Grid
              overflow={{ base: 'hidden', md: 'unset' }}
              overflowX={{ base: 'scroll', md: 'unset' }}
              filter={isDev ? '' : 'blur(3px)'}
              w={{ base: '100%', md: 'fit-content' }}
              templateColumns={{
                base: 'repeat(3, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
              gap={'20px 20px'}
              alignSelf={{ base: 'center' }}
            >
              {data &&
                data?.map((item, index) => {
                  return (
                    <>
                      {isDev ? (
                        <Link
                          key={'link' + index}
                          href={`/investors/funds/${item?.slug}`}
                          alt={item?.nameBrand}
                          // onClick={() => {
                          //   setCurrentFund(item);
                          // }}
                        >
                          <FundResearchCard
                            key={'fundCard' + index}
                            {...{
                              data: item,
                              scheme: customTextFundResearch,
                            }}
                          />
                        </Link>
                      ) : (
                        <FundResearchCard
                          key={'fundCard' + index}
                          {...{
                            data: item,
                            scheme: customTextFundResearch,
                          }}
                        />
                      )}
                    </>
                  );
                })}
            </Grid>
            {!isDev ? <ComingSoon /> : null}
          </Flex>

          <Link
            href={'/investors'}
            alt={'See more funds'}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <CustomElement
              title={'See More'}
              variant={'seeMoreBtn'}
              customStyle={{
                py: '16px',
                filter: isDev ? '' : 'blur(3px)',
              }}
            />
            <Box display={{ base: 'flex', md: 'none' }} w={'24px'}></Box>
          </Link>
        </Flex>
      </BlockWithBlueArrowTitle> */}
      <BlueInfoBox
        titleText={
          isLogIn
            ? isMobile
              ? "A better way to research startups"
              : "A better way to research startups"
            : // : 'A better way to invest'
              "A better way to research startups"
          // : 'Invest With aVenture'
        }
        text={
          isLogIn
            ? isMobile
              ? "aVenture unlocks research to leading startups that outperform public markets. Schedule a free call with an aVenture research analyst to see how can help your with you and your firm."
              : "aVenture unlocks research to leading startups that outperform public markets. Schedule a free call with an aVenture research analyst to see how can help your with you and your firm."
            : // : 'aVenture unlocks access to world changing start-ups with a diversified portfolio strategy that has historically outperformed stocks. '
              "aVenture unlocks research to leading startups that outperform public markets. Schedule a free call with an aVenture research analyst to see how can help your with you and your firm."
          // : 'Invest in venture-backed companies and funds with the aVenture platform like never before.'
        }
        // additionalText={
        //   isLogIn ? null : 'Apply today for access to our investing platform.'
        // }
        buttonText={
          isLogIn
            ? isMobile
              ? "Book Demo"
              : "Book Demo"
            : // : 'Get Investment Access'
              "Book Demo"
        }
        // px={{ base: '37px', md: '74px', lg: '43px' }}
        headingType={"h2"}
        btnData={{ href: "/sign-up", alt: "sign-up", handler: () => {} }}
        styleOfBox={{
          borderRadius: { base: "none", lg: "10px" },
          maxW: { base: "100%", lg: "50%" },
          maxH: "441px",
        }}
      />
    </MainWrapper>
  );
};

function ComingSoon() {
  return (
    <Flex
      flexDir={"column"}
      gap={"12px"}
      align={"center"}
      px={"51px"}
      w={{ base: "327px", lg: "454px" }}
      h={"232px"}
      objectFit={"contain"}
      bgPosition={"center"}
      bgRepeat={"no-repeat"}
      bgSize={"cover"}
      bgImage={`url("/images/comming-soon.png")`}
      position={"absolute"}
      top={"50%"}
      left={"50%"}
      transform={"translate(-50%, -50%)"}
      borderRadius={"4px"}
    >
      <Box mt={"12px"} mb={"20px"}>
        <Logo />
      </Box>
      <Text color={"white"} {...h_700_24_normal_48} textAlign={"center"}>
        Fund data is coming soon
      </Text>

      <Button
        bgColor={"transparent"}
        color={"white"}
        border={"1px solid white"}
        borderRadius={"4px"}
        {...h_700_14_100}
        _hover={{ backgroundColor: "transparent" }}
      >
        <Link href={"/companies"}>Explore Company Research</Link>
      </Button>
    </Flex>
  );
}

function BlueInfoBox({
  titleText,
  text,
  buttonText,
  additionalText,
  headingType,
}) {
  const router = useRouter();
  const [freeAccountByEmail] = useFreeAccountByEmailMutation();
  const isLogIn = useSelector(getUserIsVerify());
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(emailSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmitEmailHandler = (data) => {
    if (data?.email?.trim() !== "") {
      freeAccountByEmail({ email: data?.email }).then(
        (res) => res.data?.success && router.push("/free-research")
      );
    }
  };

  return (
    <Flex
      bgColor={"mainBlue"}
      // p={{
      //   base: '30px 36px',
      //   md: '78px 110px',
      //   xl: '86px 50px',
      // }}
      py={{ base: "60px", md: "50px", xl: "40px" }}
      px={"24px"}
      flexDirection={{ base: "column" }}
      alignItems={{ base: "center", md: "center" }}
      borderRadius={{ base: "none", md: "10px" }}
      textAlign={{
        base: "center",
        // md: 'left',
        md: "center",
        lg: isLogIn ? "center" : "center",
      }}
      minH={{ base: "441px", md: "441px", xl: "fit-content" }}
      h={{ base: "441px", md: "fit-content" }}
      maxH={{ base: "441px", md: "441px", xl: "441px" }}
      // flex={'1'}
      w={"100%"}
      maxW={{ base: "100%", md: "70%", lg: "50%" }}
      justify={{ base: "space-between", md: "space-between" }}
    >
      {/* <Flex
        w={{ base: '100%', md: '100%' }}
        maxW={'480px'}
        h={'full'}
        flexDir={{ base: 'column' }}
        align={{ base: 'center', md: 'center', xl: 'center' }}
        justify={'center'}
      > */}
      <RoslindaleText
        customStyle={{
          textAlign: "center",
          fontSize: { base: "32px", md: "54px" },
          lineHeight: { base: "130%", md: "normal" },
          color: "white",
          mb: "20px",
          w: "100%",
          maxW: "480px",
        }}
        headingType={headingType}
      >
        {titleText}
      </RoslindaleText>
      <Text
        textAlign={"center"}
        color={"white"}
        mb={{ base: "20px", md: "20px" }}
        {...h_400_16_135_64}
        w={{ base: "", md: "100%", xl: "100%" }}
        maxW={"480px"}
      >
        <u> {additionalText}</u> {text && text}
      </Text>
      {/* </Flex> */}
      <Flex
        maxW={{ base: "100%", xl: "100%" }}
        w={"100%"}
        flexDirection={{ base: "column", md: "row", xl: "column" }}
        justify={{ base: "unset", md: "left", lg: "center", xl: "unset" }}
        align={{
          base: "center",
          md: "center",
          xl: isLogIn ? "center" : "center",
        }}
        gap={"8px"}
        as={"form"}
        onSubmit={handleSubmit(onSubmitEmailHandler)}
      >
        {/* {isLogIn ? null : (
          <ExtendedAuthInput
            placeholder={'Enter your email'}
            {...{
              control,
              watch,
              type: 'email',
              id: 'email',
              boxStyles: { maxW: '284px' },

              styles: {
                h: '45px',
                color: 'black',
                minW: '100%',
                borderWidth: '1px',
                borderColor: 'black',
                maxW: { base: '100%', xl: '284px' },
              },
              errors,
            }}
            error_style={{
              bottom: { base: '-10px', md: '-15px', xl: '-10px' },
            }}
          />
        )} */}
        {buttonText ? (
          <Flex w={"100%"} justify={"center"}>
            <Link
              href={"https://calendly.com/aventurevc/aventure-call"}
              alt="Book Demo"
              target="_blank"
              rel="noreferrer"
            >
              <FormButton
                title={buttonText}
                // handler={() => {
                //   isLogIn ? router.push('/investing') : null;
                // }}
                button={{
                  // type: 'submit',
                  h: "45px",
                  bg: "red",
                  _hover: {
                    bg: "darkRedNew",
                  },
                  w: "180px",
                  // minW: { base: '100%', xl: '283px' },
                }}
              />
            </Link>
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  );
}
