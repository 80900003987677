import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { RoslindaleText } from '../text/RoslindaleText';
import Link from 'next/link';
import { h_400_14_100_64 } from '@/styles/fontStyles';
import { CustomElement } from '../box/CustomElement';

function BlueInfoBox({
  titleText,
  text,
  buttonText,
  handler,
  customStyle,
  px,
  styleOfBox,
  additionalText,
  headingType,
  btnData,
  styleOfAddition,
  children,
}) {
  return (
    <Flex
      bgColor={'mainBlue'}
      py={{ base: '33px', md: '78px', lg: '78px' }}
      px={px}
      flexDirection='column'
      alignItems={{ base: 'center', md: 'start', lg: 'center' }}
      borderRadius={{ base: 'none', lg: '10px' }}
      minH={'441px'}
      textAlign={{ base: 'center', md: 'start', lg: 'center' }}
      justifyContent={'center'}
      {...styleOfBox}
    >
      {children || (
        <>
          <RoslindaleText customStyle={customStyle} headingType={headingType}>
            {titleText}
          </RoslindaleText>

          <Text
            color={'white'}
            mb={{ base: '33px', md: '44px', lg: '10px', xl: '30px' }}
            {...h_400_14_100_64}
            {...styleOfAddition}
          >
            <u> {additionalText}</u> {text && text}
          </Text>

          {buttonText ? (
            <Link
              href={btnData?.href || '#'}
              alt={btnData?.alt}
              onClick={() => btnData?.handler()}
            >
              <CustomElement
                {...{ variant: 'redBtn', title: buttonText, handler }}
              />
            </Link>
          ) : null}
        </>
      )}
    </Flex>
  );
}

export default BlueInfoBox;
