'use client';

import { Flex, Input } from '@chakra-ui/react';
import { useState } from 'react';
import HideEyeIcon from '@/assets/svg/hide-eye.svg';
import { o_400_16_100 } from '@/styles/fontStyles';

const DefaultInput = ({ input, icon, styles }) => {
  const [inputType, setInputType] = useState(input?.type);

  return (
    <Flex
      alignItems={'center'}
      borderColor={'grey'}
      borderWidth={'1px'}
      borderRadius={'4px'}
      bg={'menuGray'}
      w={'100%'}
      h={'36px'}
      px={'12px'}
      {...styles}
    >
      {icon ? (
        <Flex alignItems={'center'} justifyContent={'center'} mr={'7.5px'}>
          {icon}
        </Flex>
      ) : null}
      <Input
        variant={'unstyled'}
        _placeholder={{ color: 'grey' }}
        borderRadius={'0px'}
        {...o_400_16_100}
        type={inputType}
        {...input}
      />
      {input?.type === 'password' ? (
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          cursor={'pointer'}
          onMouseEnter={() => setInputType('text')}
          onMouseLeave={() => setInputType('password')}
        >
          <HideEyeIcon />
        </Flex>
      ) : null}
    </Flex>
  );
};

export default DefaultInput;
