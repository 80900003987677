import DefaultInput from "@/components/ui/inputs/DefaultInput";
import { Box, Text } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import ErrorText from "./ErrorText";

const ExtendedAuthInput = ({
  id,
  control,
  watch,
  placeholder,
  type,
  errors,
  styles,
  disabled,
  maxLength,
  label,
  icon,
  boxStyles,
  error_style,
  inputStyles,
}) => {
  return (
    <Box pos={"relative"} w={"100%"} {...boxStyles}>
      {label ? (
        <Text
          color={"mainBlue"}
          fontSize={"14px"}
          fontWeight={"700"}
          lineHeight={"1.2"}
          letterSpacing={"0.28px"}
          mb={"9px"}
        >
          {label}
        </Text>
      ) : null}
      <Controller
        name={id}
        {...{ control }}
        render={({ field }) => (
          <DefaultInput
            input={{
              ...field,
              value: field.value || "",
              ...watch,
              type,
              id,
              placeholder,
              disabled,
              maxLength,
              ...inputStyles,
            }}
            {...{ styles, icon }}
          />
        )}
      />
      {errors?.[id] ? (
        <ErrorText title={errors?.[id]?.message} {...{ error_style }} />
      ) : null}
    </Box>
  );
};

export default ExtendedAuthInput;
